import React from "react";
import { Spinner } from "react-bootstrap";

const FullScreenLoadingSpinner = () => {
  return (
    <div
      style={{
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Spinner
        animation="border"
        role="status"
        style={{ color: "#e5476c", width: "3rem", height: "3rem" }}
      />
    </div>
  );
};

export default FullScreenLoadingSpinner;
