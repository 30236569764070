import ReactGA from "react-ga";
import { isPWA } from "../utils/browser";
import auth from "./authService";

const {
  NODE_ENV,
  REACT_APP_GA_TRACKING_ID,
  REACT_APP_GA_ENABLE_TRACKING,
  REACT_APP_GA_DEBUG,
} = process.env;

function enableGA() {
  return NODE_ENV === "production" || REACT_APP_GA_ENABLE_TRACKING;
}

export function initializeGoogleAnalytics(id) {
  if (!enableGA()) return;
  const GATrackingId = id || REACT_APP_GA_TRACKING_ID;
  const debug = REACT_APP_GA_DEBUG || false;
  if (!GATrackingId) return;

  const initOptions = { debug, gaOptions: { siteSpeedSampleRate: 100 } };

  const user = auth.getCurrentUser();

  if (user?._id) {
    initOptions.gaOptions["userId"] = user._id;
  }

  ReactGA.initialize(GATrackingId, initOptions);

  ReactGA.set({ dimension1: isPWA() ? "PWA" : "Browser" });

  reportWebVitals(sendWebVitals);
}

export function sendPageView(path) {
  if (!enableGA() || !ReactGA) return;
  ReactGA.pageview(path);
}

export function sendEvent(event) {
  if (!enableGA() || !ReactGA) return;
  ReactGA.event(event);
}

export function setUserId(userId) {
  if (!enableGA() || !ReactGA) return;
  ReactGA.set({ userId });
}

function sendWebVitals({ id, name, value }) {
  ReactGA.event({
    category: "Web Vitals",
    action: name,
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate
  });
}

function reportWebVitals(onPerfEntry) {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
}

const analyticsSerivce = {
  init: initializeGoogleAnalytics,
  pageView: sendPageView,
  event: sendEvent,
  setUserId,
};

export default analyticsSerivce;
