import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../hooks/use-auth";

const ProtectedRoute = ({ children, ...rest }) => {
  const { currentUser } = useAuth();
  if (!currentUser) return <Redirect to="/login" />;
  return <Route {...rest}>{children}</Route>;
};

export default ProtectedRoute;
