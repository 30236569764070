import lazy from "./utils/lazyWithRetry";

const Home = lazy(() => import("./components/Home"));

/* Login Components */
const Login = lazy(() => import("./components/Login/Login"));
const ResetPassword = lazy(() => import("./components/Login/ResetPassword"));
const ForgotPassword = lazy(() => import("./components/Login/ForgotPassword"));
const AutoLogin = lazy(() => import("./components/AutoLogin"));

/* Control Systems */
const RoomControl = lazy(() => import("./components/RoomControlModule"));

/* Concierge */
const Concierge = lazy(() => import("./components/Concierge/Concierge"));

/* Check-in */
const CheckIn = lazy(() => import("./components/CheckIn"));

/* Dining */
const RoomService = lazy(() => import("./components/RoomService/RoomService"));

/* Profile */
const Profile = lazy(() => import("./components/Profile/Profile"));
const Location = lazy(() => import("./components/Profile/Location"));
const Me = lazy(() => import("./components/Profile/Me"));
const Notifications = lazy(() => import("./components/Profile/Notifications"));
const Users = lazy(() => import("./components/Profile/Users"));
const EditUser = lazy(() => import("./components/UserManagement/UserForm"));

/* Misc */
const NotFound = lazy(() => import("./components/NotFound"));

/* Valet */
//const Valet = lazy(() => import("./components/Valet/Valet"));

const routes = [
  {
    path: "/",
    name: "Home",
    Component: Home,
    protect: false,
    exact: true,
  },
  {
    path: "/login",
    name: "Login",
    Component: Login,
    protect: false,
    exact: true,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    Component: ForgotPassword,
    protect: false,
    exact: true,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    Component: ResetPassword,
    protect: false,
    exact: true,
  },
  {
    path: "/autologin",
    name: "Autologin",
    Component: AutoLogin,
    protect: false,
    exact: true,
  },
  {
    path: "/profile/me",
    name: "My Profile",
    Component: Me,
    protect: true,
    exact: true,
  },
  {
    path: "/profile/users/new",
    name: "Edit User",
    Component: EditUser,
    protect: true,
    exact: true,
  },
  {
    path: "/profile/users",
    name: "Users",
    Component: Users,
    protect: true,
    exact: true,
  },
  {
    path: "/profile/notifications",
    name: "Notifications",
    Component: Notifications,
    protect: true,
    exact: true,
  },
  {
    path: "/profile/location",
    name: "Location",
    Component: Location,
    protect: true,
    exact: true,
  },
  {
    path: "/profile",
    name: "Profile",
    Component: Profile,
    protect: true,
    exact: true,
  },
  {
    path: "/room-control",
    name: "Room Control",
    Component: RoomControl,
    protect: true,
    exact: false,
  },
  {
    path: "/room-service",
    name: "Room Service",
    Component: RoomService,
    protect: true,
    exact: true,
  },
  {
    path: "/concierge",
    name: "Concierge",
    Component: Concierge,
    protect: true,
    exact: false,
  },
  // {
  //   path: "/valet",
  //   name: "Valet",
  //   Component: Valet,
  //   protect: true,
  //   exact: false,
  // },
  {
    path: "/check-in",
    name: "Check-in",
    Component: CheckIn,
    protect: false,
    exact: false,
  },
  {
    path: "/not-found",
    name: "404 Not Found",
    Component: NotFound,
    protect: true,
    exact: true,
  },
];

export default routes;
