import React, { Suspense } from "react";
import { Route as OpenRoute, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "./common/ProtectedRoute";
import analytics from "../services/analyticsService";
import Routes from "../routes";
import CSSTransition from "react-transition-group/CSSTransition";
import TransitionGroup from "react-transition-group/TransitionGroup";
import FullScreenLoadingSpinner from "./common/FullScreenLoadingSpinner";

function AnimatedSwitch() {
  return (
    <OpenRoute
      render={({ location }) => {
        analytics.pageView(location.pathname);
        return (
          <main className="main-wrapper">
            <Suspense fallback={<FullScreenLoadingSpinner />}>
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  classNames="fade"
                  timeout={300}
                >
                  <Switch location={location}>
                    {Routes.map(({ path, protect, Component, exact }) => {
                      const Route = protect ? ProtectedRoute : OpenRoute;
                      return (
                        <Route exact={exact} path={path} key={path}>
                          {({ match, ...rest }) => (
                            <div className="route">
                              <Component {...rest} />
                            </div>
                          )}
                        </Route>
                      );
                    })}
                    <Redirect to="/not-found" />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </Suspense>
          </main>
        );
      }}
    />
  );
}

export default AnimatedSwitch;
