import React from "react";
import { Button } from "react-bootstrap";

const ErrorBoundary = () => {
  const handleRedirectHome = () => {
    window.location = "/";
  };
  return (
    <div
      className="error container text-center w-100 h-100 d-flex flex-column align-items-center justify-content-center"
      style={{ position: "fixed", left: 0, right: 0 }}
    >
      <h3>Something went wrong.</h3>
      <p>
        We're very sorry about that. We've logged this error so we can get it
        fixed.
      </p>
      <Button variant="dark" onClick={handleRedirectHome}>
        Tap to Reload
      </Button>
    </div>
  );
};

export default ErrorBoundary;
