const prod = ["kohost.app"];
const dev = ["netlify.app"];

export default function getTenant() {
  if (process.env.NODE_ENV === "development")
    return process.env.REACT_APP_TENANT || "kohost-dev";
  const parts = window.location.host.split(".");

  if (parts.length === 3) {
    const tld = parts[2];
    const host = parts[1];
    const domain = `${host}.${tld}`;

    if (prod.includes(domain)) return parts[0];
    if (dev.includes(domain)) return "demo";
  }

  return null;
}
