import { lazy } from "react";

const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const version = process.env.REACT_APP_NPM_VERSION || "default";
    const versionKey = `${version}-page-has-been-force-refreshed`;
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(versionKey) || "false"
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem(versionKey, "false");

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem(versionKey, "true");
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

export default lazyWithRetry;
