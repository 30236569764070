import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

// services
import logger from "./services/loggerService";
import registerServiceWorker from "./serviceWorkerRegistration";

// CSS
import "./sass/main.scss";

function initViewport() {
  try {
    function setVh() {
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    setVh();

    window.addEventListener("resize", () => {
      setVh();
    });
  } catch (error) {}
}

initViewport();

logger.init();

ReactDOM.render(<App />, document.getElementById("root"));
registerServiceWorker();
