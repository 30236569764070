import React, { useContext, createContext } from "react";
import queryString from "query-string";
import Kohost from "@kohost/api-client";
import { useLocalStorage } from "./use-local-storage";
import getTenant from "../utils/getTenant";
import { Guest, User } from "../utils/users";
import logger from "../services/loggerService";

const authContext = createContext();

const userKey = "kohost-user";
const tokenKey = "x-auth-token";
const refreshTokenKey = "x-refresh-token";

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function getApiUrl() {
  // add api the hostname
  // eg: if the current site is https://vu.kohost.app, the api url will be https://api.vu.kohost.app
  const hostname = window.location.hostname;
  const map = {
    "vu.kohost.app": "api.vu.kohost.app",
    "dcslv.kohost.app": "api.dcslv.kohost.app",
  };

  return `https://${map[hostname]}/v2`;
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useLocalStorage(userKey);
  const [token, setToken] = useLocalStorage(tokenKey);
  const [refreshToken, setRefreshToken] = useLocalStorage(refreshTokenKey);

  let userObj = null;

  if (user) {
    if (user.type === "Guest") userObj = new Guest(user);
    else userObj = new User(user);
  }

  const initKohost = () => {
    Kohost.config.update({
      url: getApiUrl(),
      onLoginRequired: function () {
        const query = queryString.parse(window.location.search, {
          ignoreQueryPrefix: true,
        });

        const location = window.location.href;

        if (
          !location.includes("login") &&
          !location.includes("check-in") &&
          !query.token &&
          !query.checkInToken
        ) {
          window.location = "/login";
        }
      },
      lsAuthTokenKey: tokenKey,
      lsRefreshTokenKey: refreshTokenKey,
      lsUserKey: userKey,
      tenant: getTenant(),
    });
  };

  const saveUser = (user) => {
    setUser(user);
    logger.setUserScope(user);
  };

  initKohost();

  // Return the user object and auth methods
  return {
    currentUser: userObj,
    setCurrentUser: saveUser,
    token,
    setToken,
    refreshToken,
    setRefreshToken,
  };
}
