import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import getTenant from "../utils/getTenant";

function init() {
  const nodeEnv = process.env.NODE_ENV;
  const version = process.env.REACT_APP_VERSION;

  const config = {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: nodeEnv,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: nodeEnv === "development" ? 1.0 : 0.65,
    initialScope: {
      tags: { tenant: getTenant() },
    },
  };

  if (nodeEnv === "production") {
    config.release = "kohost-app:" + version;
  }

  Sentry.init(config);
}

function log(error) {
  Sentry.captureException(error);
}

function setUserScope(user) {
  if (!user) return;
  Sentry.configureScope(function (scope) {
    scope.setUser({
      id: user._id,
      email: user.email,
    });
  });
}

const loggerService = {
  init,
  log,
  setUserScope,
};

export default loggerService;
