import React, { useState, useEffect, useContext, createContext } from "react";
import Kohost from "@kohost/api-client";
import LoadingSpinner from "../components/LoadingSpinner";
import getTenant from "../utils/getTenant";
import analytics from "../services/analyticsService";

function loadManifest(manifest) {
  const content = JSON.stringify(manifest);
  const manifestBlob = new Blob([content], { type: "application/json" });
  const url = URL.createObjectURL(manifestBlob);
  const linkEl = document.getElementById("manifest");
  linkEl.href = url;
}

function getApiUrl() {
  // add api the hostname
  // eg: if the current site is https://vu.kohost.app, the api url will be https://api.vu.kohost.app
  const hostname = window.location.hostname;
  const map = {
    "vu.kohost.app": "api.vu.kohost.app",
    "dcslv.kohost.app": "api.dcslv.kohost.app",
  };

  return `https://${map[hostname]}/v2`;
}

function loadBranding(manifest) {
  if (manifest) {
    const appIcons = manifest.icons;
    const themeColor = manifest.theme_color;
    const appIcon = appIcons && appIcons[0] && appIcons[0].src;
    if (appIcon) {
      document.querySelector("#favicon").setAttribute("href", appIcon);
      document.querySelector("#apple-touch-icon").setAttribute("href", appIcon);
    }

    if (themeColor) {
      document
        .querySelector("#theme-color")
        .setAttribute("content", themeColor);
    }
  }
}

const configContext = createContext({});

export function ProvideConfig({ children }) {
  const config = useProvideConfig();
  if (!config || Object.keys(config).length === 0)
    return <LoadingSpinner fullscreen height="100%" />;
  return (
    <configContext.Provider value={config}>{children}</configContext.Provider>
  );
}

export const useConfig = () => {
  return useContext(configContext);
};

function useProvideConfig() {
  const [config, setConfig] = useState({});

  useEffect(() => {
    const createAppConfig = (config) => {
      setConfig(config);
      loadManifest(config.manifest);
      loadBranding(config.manifest);
    };

    const initKohost = () => {
      Kohost.config.update({
        url: getApiUrl(),
        tenant: getTenant(),
      });
    };

    const fetchAppConfig = async () => {
      const configRequest = await Kohost.get("/config");
      const appConfig = configRequest.data[0];
      return appConfig;
    };

    initKohost();
    fetchAppConfig().then((config) => {
      createAppConfig(config);
      analytics.init(config.gaTrackingId);
    });
  }, []);

  return config;
}

export default configContext;
