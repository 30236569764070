import React, { useContext, createContext, useRef } from "react";
import Kohost from "@kohost/api-client";
import KohostSocketIo from "@kohost/socket.io-client";
import loggerService from "../services/loggerService";

const socketIoContext = createContext();

function getApiUrl() {
  // add api the hostname
  // eg: if the current site is https://vu.kohost.app, the api url will be https://api.vu.kohost.app
  const hostname = window.location.hostname;
  const map = {
    "vu.kohost.app": "api.vu.kohost.app",
    "dcslv.kohost.app": "api.dcslv.kohost.app",
  };

  return `https://${map[hostname]}/users`;
}

export function ProvideSocketIoClient({ children }) {
  const client = useProvideSocketIoClient();
  return (
    <socketIoContext.Provider value={client}>
      {children}
    </socketIoContext.Provider>
  );
}

export const useSocketIoClient = () => {
  return useContext(socketIoContext);
};

function useProvideSocketIoClient() {
  const groupRefreshTimer = useRef(null);
  const user = Kohost.getCurrentUser();
  const config = {
    url: getApiUrl(),
    token: Kohost.getAuthToken(),
    tenant: Kohost.config.tenant,
    user: user,
    errHandler: handleConnectError,
  };
  const client = new KohostSocketIo(config);

  Kohost.config.update({
    onNewToken: (token) => client.updateToken(token),
  });

  client.on("connect", () => {
    console.log("Kohost Socket.io client connected");

    const refreshCommand = {
      commandType: "REFRESH_GROUP",
      data: { groups: user.groupAccess },
    };

    client.send("message", refreshCommand);

    if (!groupRefreshTimer.current) {
      groupRefreshTimer.current = setInterval(() => {
        client.send("message", refreshCommand);
      }, 1000 * 60);
    }
  });

  client.on("disconnect", () => {
    console.log("Kohost Socket.io client disconnected");
    if (groupRefreshTimer.current) {
      clearInterval(groupRefreshTimer.current);
      groupRefreshTimer.current = null;
    }
  });
  return client;
}

function handleConnectError(err) {
  const messages = [
    "Authentication error, no token provided",
    "Token is not valid",
    "jwt expired",
  ];
  if (messages.includes(err.message)) {
    Kohost.Auth.requestNewTokens().then(() => {
      this.updateToken(Kohost.getAuthToken());
    });
  } else {
    loggerService.log(err);
  }
}
