import { parsePhoneNumberFromString as format } from "libphonenumber-js";

export class User {
  constructor(data) {
    this._id = data._id;
    this.id = data._id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.fullName = this.fullName();
    this.phone = data.phone;
    this.phoneFormatted = this.phoneFormatted();
    this.email = data.email;
    this.type = data.type;
    this.preferences = data.preferences;
    this.location = data.location;
    this.authorization = data.authorization;
    this.checkInStatus = data.checkInStatus;
    this.avatar = data.avatar;
  }

  isAdmin() {
    return this.type === "Admin" || this.type === "SuperAdmin";
  }

  fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  phoneFormatted() {
    const phone = this.phone;
    return format(phone) ? format(phone).formatNational() : phone;
  }
}

export class Guest extends User {
  constructor(guestData) {
    super(guestData);
    this.reservations = guestData.reservations || [];
    this.roomNames = guestData.roomNames;
  }

  getCurrentReservation() {
    const now = Date.now() / 1000;

    const reservation = this.reservations.find((res) => {
      const checkInDate = Date.parse(res.checkInDateTime) / 1000;
      const checkOutDate = Date.parse(res.checkOutDateTime) / 1000;
      return now >= checkInDate && now < checkOutDate;
    });

    return reservation;
  }

  getCurrentReservations() {
    const now = Date.now() / 1000;

    const reservations = this.reservations.filter((res) => {
      const checkInDate = Date.parse(res.checkInDateTime) / 1000;
      const checkOutDate = Date.parse(res.checkOutDateTime) / 1000;
      return (
        now >= checkInDate && now < checkOutDate && res.status === "checkedIn"
      );
    });

    return reservations;
  }

  getPreviousReservations() {
    const now = Date.now() / 1000;
    const previous = this.reservations.filter((res) => {
      const checkOutDate = Date.parse(res.checkOutDateTime) / 1000;
      return now > checkOutDate;
    });
    return previous;
  }

  getConfirmedReservations() {
    const now = Date.now() / 1000;
    const confirmed = this.reservations.filter((res) => {
      const checkOutDate = Date.parse(res.checkOutDateTime) / 1000;
      return now < checkOutDate && res.status === "confirmed";
    });
    return confirmed;
  }
}

export function getActivityText({ type, title, data }) {
  let text;
  const template = data?.template ? data.template : "";

  switch (type) {
    case "email":
      text = `${template} ${title}`;
      break;
    case "sms":
      text = `${template} ${title}`;
      break;
    default:
      text = title;
      break;
  }

  return text;
}

const usersUitls = {
  User,
  Guest,
  getActivityText,
};

export default usersUitls;
