import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ProvideConfig } from "./hooks/use-config";
import { ProvideAuth } from "./hooks/use-auth";
import { ProvideSocketIoClient } from "./hooks/use-socket-io-client";
import ErrorBoundary from "./components/ErrorBoundary";
import AnimatedSwitch from "./components/AnimatedSwitch";
import * as Sentry from "@sentry/react";

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
      <ProvideConfig>
        <ProvideAuth>
          <ProvideSocketIoClient>
            <Router>
              <AnimatedSwitch />
            </Router>
          </ProvideSocketIoClient>
        </ProvideAuth>
      </ProvideConfig>
    </Sentry.ErrorBoundary>
  );
}

export default App;
