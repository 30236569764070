import Kohost from "@kohost/api-client";
import analytics from "./analyticsService";

export async function guestLogin(lastName, roomNumber, phone) {
  try {
    const cleanPhone = phone.replace(/\D+/g, "");
    const response = await Kohost.Auth.loginGuest(
      lastName,
      roomNumber,
      cleanPhone
    );
    const user = response?.data && response.data[0];
    if (user) analytics.setUserId(user._id);
    return response.data && response.data[0];
  } catch (error) {
    const errorMessage =
      error.response?.data?.error?.message || "Something failed";
    return { error: { message: errorMessage } };
  }
}

export async function userLogin(email, password) {
  const response = await Kohost.Auth.loginUser(email.toLowerCase(), password);

  const user = response?.data && response.data[0];

  if (user) analytics.setUserId(user._id);

  if (response.status >= 400 && response.status <= 500) {
    const body = {
      error: "",
    };
    body.error = response.data.error;
    return body;
  } else {
    return true;
  }
}

export function logout() {
  analytics.setUserId(null);
  Kohost.Auth.logout();
}

export function getCurrentUser() {
  try {
    return Kohost.getCurrentUser();
  } catch (ex) {}
}

export function getHotelRoomId() {
  try {
    const selectedHr = localStorage.getItem("kohost-selected-hotelRoom");
    if (selectedHr) return selectedHr;

    const user = getCurrentUser();

    if (
      !user ||
      user.type !== "Guest" ||
      !user.reservations ||
      user.reservations.length === 0
    )
      return null;
    const reservation = user.reservations.find(
      (res) => res.status === "checkedIn"
    );
    if (!reservation || !reservation.hotelRooms || !reservation.hotelRooms[0])
      return null;
    const hotelRoom = reservation.hotelRooms[0];
    return hotelRoom._id;
  } catch (ex) {
    console.log(ex);
  }
}

export function setJwt(key, token) {
  if (key === "x-auth-token") {
    localStorage.setItem("timestamp", new Date());
  }
  localStorage.setItem(key, token);
}

const authService = {
  guestLogin,
  userLogin,
  getHotelRoomId,
  logout,
  getCurrentUser,
  setJwt,
};

export default authService;
